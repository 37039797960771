<template>
  <div id="row">
    <div class="row">
      <a href="https://www.glenncambre.com" target="_blank"
        ><img id="logoLink2" src="../assets/logo.png" alt=""
      /></a>
    </div>
    <div class="col-md-12 mx-auto" id="mainDiv">
      <div class="content mx-auto">
        <img
          id="logo"
          src="https://www.glenncambre.com/wp-content/uploads/2021/08/Cambre-Assoc-Logo-Shield-IA-Lawyers-flattened.png"
          alt=""
        />
        <br />

        <br />
        <br />

        <form action="#">
          <div class="field">
            <input v-model="caseNumber" type="text" required />
            <span class="fas fa-user"></span>
            <label>Case Number</label>
          </div>
          <div class="field">
            <input v-model="userPIN" type="password" required />
            <span class="fas fa-lock"></span>
            <label>PIN</label>
          </div>
          <div class="row">
            <div class="container">
              <div class="col-12">
                <b-alert
                  style="margin-top: 25px; padding: 5px"
                  v-model="showDismissibleAlertAttempts"
                  variant="danger"
                >
                  Too many login attempts. Please try again later.
                </b-alert>
                <b-alert
                  style="margin-top: 25px; padding: 5px"
                  v-model="showDismissibleAlert"
                  variant="danger"
                >
                  Incorrent login information. Please try again.
                </b-alert>
                <b-alert
                  class="mx-auto"
                  style="margin-top: 25px; padding: 5px; width: 70%"
                  v-model="showDismissibleAlertSuccess"
                  variant="success"
                >
                  Login successful.
                </b-alert>
              </div>
            </div>
          </div>

          <button @click.prevent="submitForm">Sign in</button>
          <div class="sign-up">
            Not a client?
            <a href="https://glenncambre.com/contact/">Contact us.</a>
          </div>
        </form>
      </div>
    </div>

    <div id="disclosure" class="mx-auto d-flex justify-content-center">
      <p>
        Notice: The information contained on this website is Attorney/Client
        Privileged and confidential information intended only for the use of the
        individual or entity named above. If the reader of this message is not
        the intended recipient, you are hereby notified that any dissemination,
        distribution, or copying of this communication is strictly prohibited.
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      caseNumber: "",
      userPIN: "",
      showDismissibleAlert: false,
      showDismissibleAlertAttempts: false,
      showDismissibleAlertSuccess: false,
    };
  },
  name: "Home",
  props: {
    msg: String,
  },

  methods: {
    submitForm: function () {
      this.$store.state.caseNumber = this.caseNumber;
      localStorage.setItem("caseNumber", this.caseNumber);
      // console.log("STATE", this.$store.state.caseNumber);
      // alert(`Case Number: ${this.caseNumber} - User PIN: ${this.userPIN}`)
      // console.log("submitting");
      // console.log("lskjhdf", this.caseNumber, this.userPIN);

      if (this.userPIN == "" || this.caseNumber == "") {
        alert("Check case # and/or PIN and try again.");
      } else {
        axios
          .post("api/login", {
            caseNumber: this.caseNumber,
            userPIN: this.userPIN,
          })
          .then((response) => {
            if (response) {
              // console.log("Response: ", response.data);
            }

            if (response.data === "Please check credentials and try again.") {
              this.showDismissibleAlert = true;
              this.showDismissibleAlertAttempts = null;
            } else if (response.data === "Authorized") {
              this.showDismissibleAlert = false;
              this.showDismissibleAlertSuccess = true;
              this.$router.push("/Dashboard");
            }
            // localStorage.setItem("token", response.data.token);
            // this.$router.push("/Dashboard");
          })
          .catch((error) => {
            console.log(error);
            // console.log("lol chill");
            this.showDismissibleAlertAttempts = true;
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#logoLink2 {
  width: 10% !important;
  max-width: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

h2 {
  color: rgb(68, 68, 68);
}

#form-div {
  border-radius: 20px;
  background: linear-gradient(145deg, #c6efee, #a7c9c8);
  box-shadow: 28px 28px 25px #94b2b29a, -28px -28px 25px #deffff;
  height: 300px;
  max-width: 500px;
  width: 80%;
  margin-top: 30px;
  color: #444446;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}

.formField {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.hello {
  background: red;
}

#logo {
  margin-bottom: 45px;
}

/* #appName {
  font-size: 14px;
  margin-bottom: 40px;
} */

#mainDiv {
  max-width: 800px;
}

* {
  margin: 0;
  padding: 0;
  /* user-select: none; */
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  display: grid;
  place-items: center;
  background: #e7e9ec;
  text-align: center;
}
.content {
  width: 40%;
  min-width: 400px;
  padding: 60px 40px;
  background: #ffffff;
  border-radius: 28px;

  box-shadow: -3px -3px 20px #00000069, 2px 2px 20px rgba(0, 0, 0, 0.445);
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.content .text {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 35px;
  color: #595959;
}
.field {
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;
  left: 15%;
}
.field:nth-child(2) {
  margin-top: 20px;
}
.field input {
  height: 100%;
  width: 70%;
  padding-left: 45px;
  outline: none;
  border: none;
  font-size: 18px;
  background: #dde1e7;
  color: #595959;
  border-radius: 30px;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}
.field input:focus {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #ffffff73;
}
.field span {
  position: absolute;
  color: #595959;
  width: 50px;
  line-height: 50px;
}
.field label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 45px;
  pointer-events: none;
  color: #666666;
}

#disclosure {
  margin-top: 50px;
  font-style: italic;
  text-align: center;
  max-width: 500px;
  font-size: 12px;
  color: white;
  padding: 8px;
}
.field input:valid ~ label {
  opacity: 0;
}
.forgot-pass {
  text-align: left;
  margin: 10px 0 10px 5px;
}
.forgot-pass a {
  font-size: 16px;
  color: #3498db;
  text-decoration: none;
}
.forgot-pass:hover a {
  text-decoration: underline;
}
button {
  margin: 75px 0 10px 0;
  width: 70%;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  font-weight: 600;
  background: #dde1e7;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #595959;
  box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;
}
button:focus {
  color: #3498db;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}
.sign-up {
  margin: 10px 0;
  color: #595959;
  font-size: 16px;
}
.sign-up a {
  color: #3498db;
  text-decoration: none;
}
.sign-up a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 840px) {
  .content {
    width: 95%;
  }
}

@media screen and (max-width: 1185px) {
  #logoLink2 {
    position: absolute !important;

    top: 155% !important;

    margin: 20px auto;
  }
}
</style>
