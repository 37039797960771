import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Router from "./Router";
// import VueRouter from 'vue-router'
// import dashboard from './components/Dashboard.vue'
// import Home from './components/HelloWorld.vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "./store/index";

// Make Vue Router available to use
// Vue.use(VueRouter)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// const router = new VueRouter({
//   routes
// })

// const routes = [
//   { path: '/dashboard', component: dashboard },
//   {path: '/', component: Home}
// ]

Vue.config.productionTip = false;

new Vue({
  router: Router,
  mode: "history",
  store,
  render: (h) => h(App),
}).$mount("#app");
