<template>
  <div>
    <h1 class='weee'>{{someText}}</h1>
  </div>
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      someText: "I'm a component wahooooooo"
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.weee {
  color: red;
}

</style>