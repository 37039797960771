<template>
  <div>
    <div success v-if="!stepNewClient" class="steps">
      <b-alert class="mx-auto" id="progressAlert" show variant="primary"
        >New information regarding your case will be added soon. In the
        meantime, please contact your case manager for more information about
        your case.</b-alert
      >
    </div>

    <!-- First Progress Bar -->
    <div v-if="stepNewClient && !stepHoldHarmless">
      <ol class="steps">
        <li
          title="New Client"
          :class="[
            stepNewClient ? 'step is-active' : 'step',
            stepTreating ? 'step is-complete' : 'step',
          ]"
          data-step="1"
        >
          New Client
        </li>
        <li
          title="Treating"
          :class="[
            stepTreating ? 'step is-active' : 'step',
            stepDoneTreating ? 'step is-complete' : 'step',
          ]"
          data-step="2"
        >
          Treating
        </li>
        <li
          title="Done Treating"
          :class="[
            stepDoneTreating ? 'step is-active' : 'step',
            stepDemandReady ? 'step is-complete' : 'step',
          ]"
          data-step="3"
        >
          Done Treating
        </li>
        <li
          title="Demand Ready"
          :class="[
            stepDemandReady ? 'step is-active' : 'step',
            stepDemandComplete ? 'step is-complete' : 'step',
          ]"
          data-step="4"
        >
          Demand Ready
        </li>
        <li
          title="Demand Complete"
          :class="[
            stepDemandComplete ? 'step is-active' : 'step',
            stepNegotiation ? 'step is-complete' : 'step',
          ]"
          data-step="5"
        >
          Demand Complete
        </li>
        <li
          title="Negotiation"
          :class="stepNegotiation ? 'step is-active' : 'step'"
          data-step="6"
        >
          Negotiation
        </li>
      </ol>
    </div>
    <!-- Second Progress Bar -->
    <div v-if="stepHoldHarmless">
      <ol class="steps">
        <li
          title="Hold Harmless"
          :class="[
            stepHoldHarmless ? 'step is-active' : 'step',
            stepChecksPrinted ? 'step is-complete' : 'step',
          ]"
          data-step="1"
        >
          Hold Harmless
        </li>
        <li
          title="Accounting Procedures"
          :class="[
            stepChecksPrinted ? 'step is-active' : 'step',
            stepDisbursed ? 'step is-complete' : 'step',
          ]"
          data-step="2"
        >
          Accounting Procedures
        </li>
        <li
          title="Disbursed"
          :class="[
            stepDisbursed ? 'step is-active' : 'step',
            stepSettled ? 'step is-complete' : 'step',
          ]"
          data-step="3"
        >
          Disbursed
        </li>
        <li
          title="Settled"
          :class="stepSettled ? 'step is-active' : 'step'"
          data-step="4"
        >
          Settled
        </li>
        <!-- <li
          title="Demand Complete"
          v-b-popover.hover.top="
            `Demand sent to liability (30 days) or to UIM (60 days) waiting on response.`
          "
          :class="[
            stepDemandComplete ? 'step is-active' : 'step',
            stepNegotiation ? 'step is-complete' : 'step',
          ]"
          data-step="5"
        >
          Demand Complete
        </li>
        <li
          title="Negotiation"
          v-b-popover.hover.top="
            `Offer received. Attorney & client communication.`
          "
          :class="stepNegotiation ? 'step is-active' : 'step'"
          data-step="6"
        >
          Negotiation
        </li> -->
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStep: 1,
      // stepNewClient: false,
      // stepTreating: false,
      // stepDoneTreating: false,
      // stepDemandReady: false,
      // stepDemandComplete: false,
      // stepNegotiation: false,
      // showProgress: true,
    };
  },
  name: "ProgressBar",
  props: {
    caseStatus: String,
    stepNewClient: Boolean,
    stepTreating: Boolean,
    stepDoneTreating: Boolean,
    stepDemandReady: Boolean,
    stepDemandComplete: Boolean,
    stepNegotiation: Boolean,
    stepHoldHarmless: Boolean,
    stepChecksPrinted: Boolean,
    stepDisbursed: Boolean,
    stepSettled: Boolean,
  },

  methods: {},
  computed: {
    progressClasses() {
      let result = "progress ";
      if (this.currentStep && this.currentStep.label === "complete") {
        return (result += "progress--complete");
      }
      return (result += `progress--${
        this.steps.indexOf(this.currentStep) + 1
      }`);
    },
  },
  mounted() {
    console.log("this.caseStatus", this.caseStatus);
    if (this.caseStatus)
      // if (
      //   this.caseStatus == "New Client" ||
      //   this.caseStatus == "Treating" ||
      //   this.caseStatus == "Done Treating" ||
      //   this.caseStatus == "Demand Ready" ||
      //   this.caseStatus == "Demand Complete" ||
      //   this.caseStatus == "Negotiation"
      // ) {
      // switch (this.caseStatus) {
      //   case "New Client":
      //     this.stepNewClient = true;
      //     this.stepTreating = false;
      //     this.stepDoneTreating = false;
      //     this.stepDemandReady = false;
      //     this.stepDemandComplete = false;
      //     this.stepNegotiation = false;

      //     break;
      //   case "Treating":
      //     this.stepNewClient = true;
      //     this.stepTreating = true;
      //     this.stepDoneTreating = false;
      //     this.stepDemandReady = false;
      //     this.stepDemandComplete = false;
      //     this.stepNegotiation = false;

      //     break;
      //   case "Done Treating":
      //     this.stepNewClient = true;
      //     this.stepTreating = true;
      //     this.stepDoneTreating = true;
      //     this.stepDemandReady = false;
      //     this.stepDemandComplete = false;
      //     this.stepNegotiation = false;

      //     break;
      //   case "Demand Ready":
      //     this.stepNewClient = true;
      //     this.stepTreating = true;
      //     this.stepDoneTreating = true;
      //     this.stepDemandReady = true;
      //     this.stepDemandComplete = false;
      //     this.stepNegotiation = false;

      //     break;

      //   case "Demand Complete":
      //     this.stepNewClient = true;
      //     this.stepTreating = true;
      //     this.stepDoneTreating = true;
      //     this.stepDemandReady = true;
      //     this.stepDemandComplete = true;
      //     this.stepNegotiation = false;

      //     break;

      //   case "Negotiation":
      //     this.stepNewClient = true;
      //     this.stepTreating = true;
      //     this.stepDoneTreating = true;
      //     this.stepDemandReady = true;
      //     this.stepDemandComplete = true;
      //     this.stepNegotiation = true;

      //     break;
      // }

      console.log(this.stepNewClient);
    console.log(this.stepTreating);
    // } else {
    //   this.showProgress = false;
    // }
  },
};
</script>

<style lang="scss">
// @import "//fonts.googleapis.com/css?family=Open+Sans";

$complete: #d8d8d8;
$active: #5bc5c0;
$mute: #9b9b9b;

#progressAlert {
  max-width: 500px;
}

.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: darken($mute, 33%);
  height: 4rem;

  > .step {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 0.85rem;
    color: #4b4b4b;

    &:before {
      content: attr(data-step);
      display: block;
      margin: 0 auto;
      background: #ffffff;
      border: 2px solid $mute;
      color: $mute;
      width: 2rem;
      height: 2rem;
      text-align: center;
      margin-bottom: -4.2rem;
      line-height: 1.9rem;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-weight: 700;
      font-size: 1rem;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      background: $mute;
      width: 100%;
      height: 0.125rem;
      top: 1rem;
      left: 50%;
    }
    &:last-child:after {
      display: none;
    }
    &.is-complete {
      color: #6d6875;

      &:before {
        content: "\2713";
        color: $complete;
        background: #5bc5c0;
        border: 2px solid $complete;
      }
      &:after {
        background: $complete;
      }
    }
    &.is-active {
      font-size: 0.85rem;

      &:before {
        color: #fff;
        border: 2px solid $complete;
        background: $active;
        margin-bottom: -4.2rem;
      }
    }
  }
}

/**
 * Some Generic Styling
 */
*,
*:after,
*:before {
  box-sizing: border-box;
}
h1 {
  margin-bottom: 1.5em;
}
.steps {
  margin-bottom: 3em;
}
body {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: #6d6875;
}

@media screen and (max-width: 840px) {
  .step,
  .is-complete,
  .is-active {
    font-size: 10px !important;
  }
}
</style>
