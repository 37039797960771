import Vue from "vue";
import Router from "vue-router";
import Home from "./components/HelloWorld.vue";
import Dashboard from "./components/Dashboard.vue";
import Test from "./components/Test.vue";
import NotFound from "./components/NotFound.vue";

Vue.use(Router);

export default new Router({
  // mode: 'history',
  mode: "hash",
  history: true,

  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/test",
      name: "Test",
      component: Test,
    },
    {
      path: "*",
      name: "NotFound",
      component: NotFound,
    },
  ],
});
