var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.stepNewClient)?_c('div',{staticClass:"steps",attrs:{"success":""}},[_c('b-alert',{staticClass:"mx-auto",attrs:{"id":"progressAlert","show":"","variant":"primary"}},[_vm._v("New information regarding your case will be added soon. In the meantime, please contact your case manager for more information about your case.")])],1):_vm._e(),(_vm.stepNewClient && !_vm.stepHoldHarmless)?_c('div',[_c('ol',{staticClass:"steps"},[_c('li',{class:[
          _vm.stepNewClient ? 'step is-active' : 'step',
          _vm.stepTreating ? 'step is-complete' : 'step' ],attrs:{"title":"New Client","data-step":"1"}},[_vm._v(" New Client ")]),_c('li',{class:[
          _vm.stepTreating ? 'step is-active' : 'step',
          _vm.stepDoneTreating ? 'step is-complete' : 'step' ],attrs:{"title":"Treating","data-step":"2"}},[_vm._v(" Treating ")]),_c('li',{class:[
          _vm.stepDoneTreating ? 'step is-active' : 'step',
          _vm.stepDemandReady ? 'step is-complete' : 'step' ],attrs:{"title":"Done Treating","data-step":"3"}},[_vm._v(" Done Treating ")]),_c('li',{class:[
          _vm.stepDemandReady ? 'step is-active' : 'step',
          _vm.stepDemandComplete ? 'step is-complete' : 'step' ],attrs:{"title":"Demand Ready","data-step":"4"}},[_vm._v(" Demand Ready ")]),_c('li',{class:[
          _vm.stepDemandComplete ? 'step is-active' : 'step',
          _vm.stepNegotiation ? 'step is-complete' : 'step' ],attrs:{"title":"Demand Complete","data-step":"5"}},[_vm._v(" Demand Complete ")]),_c('li',{class:_vm.stepNegotiation ? 'step is-active' : 'step',attrs:{"title":"Negotiation","data-step":"6"}},[_vm._v(" Negotiation ")])])]):_vm._e(),(_vm.stepHoldHarmless)?_c('div',[_c('ol',{staticClass:"steps"},[_c('li',{class:[
          _vm.stepHoldHarmless ? 'step is-active' : 'step',
          _vm.stepChecksPrinted ? 'step is-complete' : 'step' ],attrs:{"title":"Hold Harmless","data-step":"1"}},[_vm._v(" Hold Harmless ")]),_c('li',{class:[
          _vm.stepChecksPrinted ? 'step is-active' : 'step',
          _vm.stepDisbursed ? 'step is-complete' : 'step' ],attrs:{"title":"Accounting Procedures","data-step":"2"}},[_vm._v(" Accounting Procedures ")]),_c('li',{class:[
          _vm.stepDisbursed ? 'step is-active' : 'step',
          _vm.stepSettled ? 'step is-complete' : 'step' ],attrs:{"title":"Disbursed","data-step":"3"}},[_vm._v(" Disbursed ")]),_c('li',{class:_vm.stepSettled ? 'step is-active' : 'step',attrs:{"title":"Settled","data-step":"4"}},[_vm._v(" Settled ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }