<template>
  <div class="div">
    <div class="hello">
      <div class="greeting">
        <!-- <div class="container mx-auto d-flex justify-content-center">
      <h4 style='text-align: left; padding-left: 20px' id='firstFade'>Hello, Alex</h4>

</div>

<div class="container mx-auto d-flex justify-content-center">
      <h6 style='text-align: left; padding-left: 20px' id='secondFade'>Here's some information regarding your case</h6>

</div> -->
      </div>
      <div class="row">
        <a href="https://www.glenncambre.com" target="_blank"
          ><img id="logoLink2" src="../assets/logo.png" alt=""
        /></a>
      </div>

      <div class="">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="content container mx-auto">
            <b-tabs pills align="center" content-class="mt-3">
              <b-tab title="Case Progress" active
                ><p></p>

                <br /><br />

                <!-- <button @click.prevent="logout"></button> -->

                <h4 v-if="!pendingInformation">Case Progress</h4>

                <br /><br /><br />
                <ProgressBar
                  :caseStatus="caseStatus"
                  :stepTreating="stepTreating"
                  :stepDoneTreating="stepDoneTreating"
                  :stepDemandReady="stepDemandReady"
                  :stepDemandComplete="stepDemandComplete"
                  :stepNewClient="stepNewClient"
                  :stepNegotiation="stepNegotiation"
                 
                  :stepHoldHarmless="stepHoldHarmless"
                  :stepChecksPrinted="stepChecksPrinted"
                  :stepDisbursed="stepDisbursed"
                  :stepSettled="stepSettled"
                ></ProgressBar>

                <b-card
                
                  class="contactCards"
                  style="border: none; padding: 5px"
                  title=""
                >
                  <br />
                  <b-card-text>
                    <!-- Your case is currently in
                    <span
                      style="color: #5bc5c0; text-shadow: 1px 1px 2px lightgrey"
                      >{{ caseStatus }}</span
                    >
                    status. -->
                    <p
                      class="statusDescription"
                      v-if="caseStatus == 'New Client'"
                    >
                      Thank you for choosing Cambre &amp; Associates to
                      represent you in your case against the at-fault party and
                      their insurance company. <br /><br />
                      We are currently processing your information and verifying
                      we have all the correct details to process your claim.
                      <br /><br />Once this is completed, we will file your
                      claim with the at-fault party's insurance company and
                      contact them to request that they accept liability.
                    </p>
                    <p
                      class="statusDescription"
                      v-if="caseStatus == 'Treating'"
                    >
                      Your claim has been filed and you should continue to
                      attend all your medical appointments. It is vital to your
                      case that you follow through with your doctors'
                      recommendations for treatment until they conclude you can
                      be released back to good health.
                      <br /><br />

                      If you have any questions regarding your medical
                      appointments or need to make a change, please "Request
                      Contact" above, so that we can assist you.<br /><br />

                      It is important that you do not have any unnecessary time
                      gaps in treatment of your injuries.
                    </p>
                    <p
                      class="statusDescription"
                      v-if="caseStatus == 'Done Treating'"
                    >
                      Now that you have completed your medical treatments, we
                      will gather all the bills and records from your medical
                      providers to include in your demand for payment of your
                      claim. We must include all the bills and records that are
                      applicable to your case, such as the following... <br/><br/>
                      <ul>
                      <li>Ambulance</li>
                      <li>Hospital</li> 
                      <li>Chiropractor</li> 
                      <li>Surgeon</li> 
                      <li>Orthopedics</li> 
                      <li>Physical Therapy</li>
                      </ul>
                      <br/><br/>
                      Sometimes the delivery of bills and records are delayed
                      from the medical providers, so patience is necessary.
                      However, you can also request the bills and records
                      yourself, which may speed up the process.<br/><br/>
                      In some cases, you may also have lost wages that can be included, please "Request Contact" above if you require the form to apply.
                    </p>
                    <p
                      class="statusDescription"
                      v-if="caseStatus == 'Demand Ready'"
                    >
                      Now that we have received all the bills and records from your medical providers that apply to your case, we will draft the legal demand for payment of your claim. <br/><br/>

The draft will then be reviewed by the Managing Partner Attorney for final edits and approval. 

                    </p>
                    <p
                      class="statusDescription"
                      v-if="caseStatus == 'Demand Complete'"
                    >
                      Your demand for payment has been completed and sent by certified mail to the at-fault party's insurance adjuster. <br/><br/>

The at-fault party's insurance company then has up to thirty (30) days to respond to a claim of liability.<br/><br/>

If you approved to file the claim against your own Uninsured Motorist (UM) or Under Insured Motorist (UIM) insurance coverage, your insurance company has up to sixty (60) days to respond.<br/><br/>

This is the time when most clients get antsy because there is nothing more to do but wait. Please be patient as we will contact you as soon as we hear something. The insurance companies are required to respond in the timeframe lest they be held in bad faith by the courts.

                    </p>
                    <p
                      class="statusDescription"
                      v-if="caseStatus == 'Negotiation'"
                    >
                      At this point we have received your first offer for settlement from the at-fault party's insurance company.<br/><br/>

Now is the time that we will contact you to begin negotiations between you and the insurance company with the goal of reaching the best and final top offer.<br/><br/>

The Attorney assigned to your case will advise you on the strategy and possibility of reaching the top offer.<br/><br/>

Keep in mind that there are limitations of the at-fault party's insurance policy, as well as the type and extent of your injuries for reaching the top offer. However, rest assured that your Attorney will fight for you to get the most money you deserve!

                    </p>
                                        <p
                      class="statusDescription"
                      v-if="caseStatus == 'Hold Harmless'"
                    >
                     This is the stage when you have agreed to accept the top offer from the at-fault party's insurance company and a Hold Harmless Agreement is sent to the adjuster.

 <br><br>

Before the settlement can be funded by the at-fault party's insurance company, a Hold Harmless Agreement is used as a release of liability and to forever discharge all claims of the aforesaid incident, including all claims for bodily injury, personal injury, medical expenses, pain and suffering, lost wages, punitive damages, attorneys' fees, costs, and any other type of damage of whatsoever kind and nature.

                    </p>
                                    
                                        <p
                      class="statusDescription"
                      v-if="caseStatus == 'Checks Printed'"
                    >
                  At this stage the firm's accounting department has received the closeout package from the Attorney and the closeout process has started but is not complete. Checks have been prepared for you, the legal fees, and the medical providers to pay for your medical bills, however the liability release must be received before check disbursement.

                    </p>
                                                            <p
                      class="statusDescription"
                      v-if="caseStatus == 'Disbursed'"
                    >
                   Also known as Settlement Distribution Payout, at this stage the firm's accounting department has completed processing the closeout of your case and has sent the file to the Front Desk for disbursement.
<br><br>
The firm's Front Desk will contact you to arrange for signatures of receipt and/or ACH information to deposit settlement amounts into your bank account or schedule time for you to pick up the check in our offices.

                    </p>
                                                            <p
                      class="statusDescription"
                      v-if="caseStatus == 'Settled'"
                    >
                     The settlement of your claim against the at-fault party's insurance company is now complete. Payouts have been sent to all parties, a copy of your signed General Release is sent to the adjuster to close out their claim. All paperwork and legal documents are archived, and the case is considered closed.

                    </p>
                  </b-card-text>

                  <br />

                  <!-- <a
                    target="_blank"
                    href="https://glenncambre.com/personal-injury-accident-lawyer-atlanta-free-guides/"
                    class="card-link"
                    >Learn More</a
                  > -->
                  <!-- <b-link href="#" class="card-link">Connect With Us</b-link> -->
                </b-card>

                <br />

                <!-- <b-card-group style="border-radius: 20px; margin: 3px">
  <b-card style="border-radius: 20px; margin: 3px" class="col-md-4 contactCards overflow-hidden " header="Your Case Manager">
    <b-list-group >
      <b-list-group-item>Laura Ruiz</b-list-group-item>
      <b-list-group-item href="tel:555-555-5555">ruiz@glenncambre.com</b-list-group-item>
      <b-list-group-item href="tel:555-555-5555">470-391-2930</b-list-group-item>
    </b-list-group>

    <p class="card-text mt-2">  
    </p>
  </b-card>

  <b-card style="border-radius: 20px; margin: 3px" class="col-md-4 contactCards overflow-hidden " header="Your Attorney">
    <b-list-group >
      <b-list-group-item>Alex Perwich</b-list-group-item>
      <b-list-group-item href="tel:555-555-5555">perwich@glenncambre.com</b-list-group-item>
      <b-list-group-item href="tel:555-555-5555">470-391-2931</b-list-group-item>
    </b-list-group>

    <b-card-body>
 
    </b-card-body>
  </b-card>
</b-card-group> -->

                <!-- ////////////////////////////////////////////// -->

                <!-- <br><br><p></p>
<div class="col-md-6 mx-auto">
  <button @click.prevent="requestContact" class='hover'>Request Contact</button>
</div>
<p></p> -->

                <!-- </b-tab> -->
                <!-- <b-tab title="Comments" ><p>
  
      <b-card-group deck>
      <b-card bg-variant="light" header="Light" class="text-center">
        <b-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</b-card-text>
      </b-card>
      </b-card-group>-->
              </b-tab>
              <!-- <b-tab title="Your Team">
                <RequestContact
                  :attorneyName="attorneyName"
                  :clientName="clientName"
                  :caseManagerName="caseManagerName"
                  :caseNumber="caseNumber"
                  :caseManagerEmail="caseManagerEmail"
                  :CMPhoneNumber="CMPhoneNumber"
                  :attorneyEmail="attorneyEmail"
                  :caseStatus="caseStatus"
                ></RequestContact
              ></b-tab> -->
              <b-tab style="border-radius: 30px" title="Request Contact">
                <RequestContact
                  :attorneyName="attorneyName"
                  :clientName="clientName"
                  :caseManagerName="caseManagerName"
                  :caseNumber="caseNumber"
                  :caseManagerEmail="caseManagerEmail"
                  :CMPhoneNumber="CMPhoneNumber"
                  :attorneyEmail="attorneyEmail"
                  :caseStatus="caseStatus"
                  :attorneyPhoneNumber="attorneyPhoneNumber"
                  :globalID="globalID"
                  :recordID="recordID"
                ></RequestContact>
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <!-- <h2 class="">
Client Portal</h2> -->
        <br />
        <br />

        <form action="#"></form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ProgressBar from "./ProgressBar.vue";
import RequestContact from "./RequestContact.vue";
import logoImport from "../assets/logo.png";

export default {
  data() {
    return {
      caseNumber: "",
      statusDescription: "",
      recordID: "",
      userPIN: "",
      attorneyName: "",
      caseManagerName: "",
      caseStatus: "",
      clientName: "",
      caseManagerEmail: "",
      attorneyEmail: "",
      CMPhoneNumber: "",
      attorneyPhoneNumber: "",
      globalID: "",
      pendingInformation: false,
      logo: logoImport,
      validated: true,
      stepNewClient: null,
      stepTreating: null,
      stepDoneTreating: null,
      stepDemandReady: null,
      stepDemandComplete: null,
      stepNegotiation: null,

      stepHoldHarmless: null,
      stepChecksPrinted: null,
      stepDisbursed: null,
      stepSettled: null,

      showProgress: true,
    };
  },

  name: "Dashboard",
  props: {
    msg: String,
  },
  components: { ProgressBar, RequestContact },

  methods: {
    logout: function () {
      localStorage.clear();
      this.$router.push("/");
    },
    submitForm: function () {
      alert(`Case Number: ${this.caseNumber} - User PIN: ${this.userPIN}`);

      // axios
      //   .post("/user", {
      //     caseNumber: "Fred",
      //     userPIN: "Flintstone",
      //   })
      //   .then(function (response) {
      //     console.log(response);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    },
  },
  // created: function () {
  //   console.log("created");
  // },
  mounted: function () {
    // let token = localStorage.getItem("token");
    // console.log("token from localstorage:", token);

    let postBody;

    if (this.$store.state.caseNumber !== "") {
      postBody = {
        caseNumber: this.$store.state.caseNumber,
      };
      this.caseNumber = this.$store.state.caseNumber;
    } else if (localStorage.getItem("caseNumber") !== "") {
      this.caseNumber = localStorage.getItem("caseNumber");
      postBody = {
        caseNumber: localStorage.getItem("caseNumber"),
      };
    } else {
      console.log("fix me please");
    }

    

    // let postBody = {
    //   caseNumber: this.$store.state.caseNumber,
    // };

    try {
      axios
        .post("api/clientData", postBody)
        .then((response) => {
          console.log(response);
          const {
            id,
            AssignedToAttorney_Pretty,
            AssignedToParalegal_Pretty,
            CMEmailAddress,
            CMPhoneNumber,
            CaseStatus,
            ClientName,
            // ClientPIN,
            RecordID,
            attorneyEmailAddress,
            attorneyPhoneNumber,
            id_UUID,
          } = response.data.response.data[0].fieldData;

          console.log(
            id,
            AssignedToAttorney_Pretty,
            AssignedToParalegal_Pretty,
            CMEmailAddress,
            CMPhoneNumber,
            CaseStatus,
            ClientName,
            // ClientPIN,
            RecordID,
            attorneyEmailAddress,
            attorneyPhoneNumber,
            id_UUID
          );

          this.attorneyName = AssignedToAttorney_Pretty;
          this.caseManagerName = AssignedToParalegal_Pretty;
          this.clientName = ClientName;
          this.caseStatus = CaseStatus;
          this.caseManagerEmail = CMEmailAddress;
          this.CMPhoneNumber = CMPhoneNumber;
          this.attorneyEmail = attorneyEmailAddress;
          this.attorneyPhoneNumber = attorneyPhoneNumber;
          this.recordID = RecordID;
          this.caseNumber = id;
          this.globalID = id_UUID;

          if (this.caseManagerName == "") {
            this.caseManagerName = "Contact Us";
          }
          if (this.attorneyName == "") {
            this.attorneyName = "Contact Us";
          }

          if (this.attorneyPhoneNumber == "") {
            this.attorneyPhoneNumber = "(770) 502-6116";
          }
          if (this.CMPhoneNumber == "") {
            this.CMPhoneNumber = "(770) 502-6116";
          }

          if (response.data == "error") {
            this.showDismissibleAlert = true;
            this.showDismissibleAlertAttempts = null;
          } else {
            this.showDismissibleAlert = false;
            this.showDismissibleAlertSuccess = true;
          }

          if (this.caseStatus == "New Client") {
            this.statusDescription = `Processing/verifying client's information. Claim set up.`;
            this.stepNewClient = true;
          }

          if (this.caseStatus == "Treating") {
            this.statusDescription = "Treatment is ongoing.";
            this.stepNewClient = true;
            this.stepTreating = true;
          }
          if (this.caseStatus == "Done Treating") {
            this.statusDescription =
              "Gathering bills & records from providers.";
            this.stepNewClient = true;
            this.stepTreating = true;
            this.stepDoneTreating = true;
          }
          if (this.caseStatus == "Demand Ready") {
            this.statusDescription =
              "Drafting demand. Managing partner review & approval.";
            this.stepNewClient = true;
            this.stepTreating = true;
            this.stepDoneTreating = true;
            this.stepDemandReady = true;
          }
          if (this.caseStatus == "Demand Complete") {
            this.statusDescription =
              "Demand sent to liability (30 days) or to UIM (60 days) waiting on response.";
            this.stepNewClient = true;
            this.stepTreating = true;
            this.stepDoneTreating = true;
            this.stepDemandReady = true;
            this.stepDemandComplete = true;
          }
          if (this.caseStatus == "Negotiation") {
            this.statusDescription = `\nAt this point we have received your first offer for settlement from the at-fault party’s insurance company. \n \n

Now is the time that we will contact you to begin negotiations between you and the insurance company with the goal of reaching the best and final top offer.

The Attorney assigned to your case will advise you on the strategy and possibility of reaching the top offer.

Keep in mind that there are limitations of the at-fault party’s insurance policy, as well as the type and extent of your injuries for reaching the top offer. However, rest assured that your Attorney will fight for you to get the most money you deserve!
`;
            this.stepNewClient = true;
            this.stepTreating = true;
            this.stepDoneTreating = true;
            this.stepDemandReady = true;
            this.stepDemandComplete = true;
            this.stepNegotiation = true;
          }

          // if(this.caseStatus !== "New Client" || this.caseStatus !== "Treating" || this.caseStatus !== "Done Treating" || this.caseStatus !== "Demand Ready" || this.caseStatus !== "Demand Complete" || this.caseStatus !== "Negotiation" || this.caseStatus !== "Hold Harmless" || this.caseStatus !== "Checks Printed" || this.caseStatus !== "Disbursed" || this.caseStatus !== "Settled"){
          //   this.pendingInformation = true
          // }
          if (this.caseStatus == "Hold Harmless") {
            this.statusDescription =
              "Hold Harmless some text here";
                          this.stepNewClient = true;
            this.stepTreating = true;
            this.stepDoneTreating = true;
            this.stepDemandReady = true;
            this.stepDemandComplete = true;
            this.stepNegotiation = true;
            this.stepHoldHarmless = true;

          }
                    if (this.caseStatus == "Checks Printed") {
            this.statusDescription =
              "Checks Printed some text here";
                          this.stepNewClient = true;
            this.stepTreating = true;
            this.stepDoneTreating = true;
            this.stepDemandReady = true;
            this.stepDemandComplete = true;
            this.stepNegotiation = true;
            this.stepHoldHarmless = true;
            this.stepChecksPrinted = true

          }

                    if (this.caseStatus == "Disbursed") {
            this.statusDescription =
              "Disbursed";
                          this.stepNewClient = true;
            this.stepTreating = true;
            this.stepDoneTreating = true;
            this.stepDemandReady = true;
            this.stepDemandComplete = true;
            this.stepNegotiation = true;
            this.stepHoldHarmless = true;
                  this.stepChecksPrinted = true
                        this.stepDisbursed = true

          }

                              if (this.caseStatus == "Settled") {
            this.statusDescription =
              "Settled";
                          this.stepNewClient = true;
            this.stepTreating = true;
            this.stepDoneTreating = true;
            this.stepDemandReady = true;
            this.stepDemandComplete = true;
            this.stepNegotiation = true;
            this.stepHoldHarmless = true;
                  this.stepChecksPrinted = true
                        this.stepDisbursed = true
                           this.stepSettled = true

          }



          console.log('status', this.caseStatus)

          // localStorage.setItem("token", response.data.token);
          // this.$router.push("/Dashboard");
        })
        .catch((error) => {
          console.log(error);

          // console.log("lol chill");
          this.showDismissibleAlertAttempts = true;
          this.$router.push("/");
        });
    } catch (error) {
      alert("Session Expired.");
    }

    // if (
    //   this.caseStatus == "New Client" ||
    //   this.caseStatus == "Treating" ||
    //   this.caseStatus == "Done Treating" ||
    //   this.caseStatus == "Demand Ready" ||
    //   this.caseStatus == "Demand Complete" ||
    //   this.caseStatus == "Negotiation"
    // ) {
    //   this.pendingInformation = false;
    // } else {
    //   this.pendingInformation = true;
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #firstFade {
    -webkit-animation: .05s ease 0s normal forwards 1 fadein;
    animation: 2s ease 0s normal forwards 1 fadein;
}

#secondFade{
      -webkit-animation: .09s ease 0s normal forwards 1 fadein;
    animation: 5s ease 0s normal forwards 1 fadein;
}  */

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
ul {
  list-style-type: none;
  padding: 0;
}

.formField {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.greeting {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: fadeinout 5s linear forwards;
  animation: fadeinout 7s linear forwards;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.statusDescription {
  max-width: 450px;
  margin: 0 auto;
  text-align: left !important;
}


.contactCards:hover {
  background: linear-gradient(145deg, #ffffff34, #f3f3f342);
  box-shadow: 12px 12px 24px #d9d9d92c, -12px -12px 24px #ffffff38;
  transition-duration: 0.1;
}

* {
  margin: 0;
  padding: 0;
  /* user-select: none; */
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  display: grid;
  place-items: center;
  background: #e7e9ec;
  /* text-align: center; */
}

.formClass {
  /* text-align: center; */
  font-weight: bold;
}
.content {
  z-index: 100;
  width: 750px;
  max-width: 750px;
  padding: 10px 10px 35px 10px;
  background: #ffffff;
  border-radius: 30px;

  box-shadow: -3px -3px 10px #4b4b4b05, 2px 2px 10px rgba(0, 0, 0, 0.5);
  /* -webkit-animation: .3s ease 0s normal forwards 1 fadein;
    animation: 3s ease 0s normal forwards 1 fadein; */

  /* -webkit-animation: fadein 7s;
       -moz-animation: fadein 7s;
        -ms-animation: fadein 7s;
         -o-animation: fadein 7s;
            animation: fadein 7s; */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.content .text {
  font-size: 33px;
  font-weight: 600;

  color: #595959;
}

li {
  text-align: left;
}

.infoList {
  font-weight: bold;
}

h3 {
  color: rgb(51, 51, 51);
}
.field {
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;
}
.field:nth-child(2) {
  /* margin-top: 20px; */
}
.field input {
  height: 100%;
  width: 100%;
  padding-left: 45px;
  outline: none;
  border: none;
  font-size: 18px;
  background: #dde1e7;
  color: #595959;
  border-radius: 25px;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}
.field input:focus {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #ffffff73;
}
.field span {
  position: absolute;
  color: #595959;
  width: 50px;
  line-height: 50px;
}
.field label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 45px;
  pointer-events: none;
  color: #666666;
}
.field input:valid ~ label {
  opacity: 0;
}
.forgot-pass {
  text-align: left;
  margin: 10px 0 10px 5px;
}
.forgot-pass a {
  font-size: 16px;
  color: #3498db;
  text-decoration: none;
}
.forgot-pass:hover a {
  text-decoration: underline;
}

#logoLink2 {
  width: 10% !important;
  max-width: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

#logoLink {
  position: absolute;
  top: 0;
  left: 0;
  margin: 3px;
  width: 150px;
  padding: 10px;
  border-radius: 30px;
  background: linear-gradient(145deg, #ffffff, #ffffff);
  border: 1px solid rgba(255, 255, 255, 0.199);
}

#logoLink:hover {
  border-radius: 30px;

  background: rgb(255, 255, 255);
  transition: 0.2s;
  padding: 10px;
  background: linear-gradient(145deg, #ffffff, #ffffff);

  box-shadow: 5px 5px 10px #00000027, -5px -5px 10px #0000000e;
}
button {
  margin: 0px 0;
  width: 100%;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  font-weight: 600;
  background: #dde1e7;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #595959;
  box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;
}
button:focus {
  color: #3498db;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}
.sign-up {
  margin: 0px 0;
  color: #595959;
  font-size: 16px;
}
.sign-up a {
  color: #3498db;
  text-decoration: none;
}
.sign-up a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 840px) {
  .content {
    width: 95%;
  }
}

@media screen and (max-width: 1185px) {
  #logoLink2 {
    position: absolute !important;

    top: 155% !important;

    margin: 20px auto;
  }
}
</style>
