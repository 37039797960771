<template>
  <div>
    <h1>Page Not Found.</h1>
    <br /><br /><br /><br />
    <button class="btn btn-success" @click.prevent="home">Go back home.</button>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      someText: "I'm a component wahooooooo",
    };
  },
  methods: {
    home: function () {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
h1 {
  color: white;
}
</style>
