<template>
  <div class="row">
    <div class="col-12" id="requestContactContainer">
      <div class="row">
        <div class="col-6 mx-auto" style="font-size: 20px; font-weight: 600">
          <p>Need to speak with someone?</p>
          <!-- <button @click.prevent="requestContact">Click Me</button> -->
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-8 mx-auto smallText">
          Here's the contact information for your case manager and your
          attorney. Feel free to reach out either by phone or email and they
          will respond to you as soon as possible.
        </div>
      </div>

      <br />

      <b-card-group style="border-radius: 20px; margin: 3px">
        <b-card
          style="border-radius: 20px; margin: 3px"
          class="col-xs-12 col-sm-6 col-md-4 contactCards overflow-hidden"
          header="Your Case Manager"
        >
          <b-list-group>
            <b-list-group-item>{{ caseManagerName }}</b-list-group-item>
            <b-list-group-item v-if="attorneyEmail">{{
              caseManagerEmail
            }}</b-list-group-item>
            <b-list-group-item>{{ CMPhoneNumber }}</b-list-group-item>
          </b-list-group>

          <p class="card-text mt-2"></p>
        </b-card>

        <b-card
          style="border-radius: 20px; margin: 3px"
          class="col-xs-6 col-sm-6 contactCards overflow-hidden"
          header="Your Attorney"
        >
          <b-list-group>
            <b-list-group-item>{{ attorneyName }}</b-list-group-item>
            <b-list-group-item v-if="attorneyEmail">{{
              attorneyEmail
            }}</b-list-group-item>
            <b-list-group-item>{{ attorneyPhoneNumber }}</b-list-group-item>
          </b-list-group>

          <b-card-body> </b-card-body>
        </b-card>
      </b-card-group>
    </div>
    <br />
    <hr />
    <br />
    <div class="row">
      <div class="col-6 mx-auto">
        <p v-if="!disableButton && !requested" class="smallText">
          Additionally, if you'd like someone to contact you, please leave a
          short note and press "Request Contact".
        </p>
      </div>
      <div class="col-7 mx-auto mb-3">
        <b-form-textarea
          class="scroll"
          v-if="!disableButton && !requested"
          id="textarea"
          v-model="clientNote"
          @change="checkLength"
          placeholder="Type reason for contact request..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>
    </div>
    <div class="col-md-6 mx-auto">
      <b-button
        v-if="!disableButton && !requested"
        @click.prevent="requestContact"
        variant="outline-primary"
        id="bootstrapButton"
        size="lg"
      >
        Submit Request
      </b-button>

      <!-- <p id='disabledText' style='border-radius: 20px !important' v-if="disableButton" >We received your request - Please allow up to 48 hours to receive a response.</p> -->
      <b-alert
        style="border-radius: 20px !important"
        v-if="disableButton"
        variant="danger"
        show
        >We've received your request & will be reaching out to you soon. Please
        allow up to 48 hours to receive a response.</b-alert
      >
      <b-alert
        style="border-radius: 20px !important"
        v-if="requested && !disableButton"
        variant="primary"
        show
        >We received your request! Please allow up to 48 hours to receive a
        response.</b-alert
      >

      <!-- <b-progress v-show='showProgress' :value="progressNumber" variant="info" :striped="striped" class="mt-2"></b-progress> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      currentStep: 1,
      disableButton: false,
      requested: false,
      progressNumber: 0,
      showProgress: false,
      clientNote: "",
      attorneyNameRendered: "",
      attorneyEmailRendered: "",
      caseManagerNameRendered: "",
      caseManagerEmailRendered: "",
      CMPhoneNumberRendered: "",
      attorneyPhoneNumberRendered: "",
      steps: [
        { label: "one" },
        { label: "two" },
        { label: "three" },
        { label: "complete" },
      ],
    };
  },
  name: "RequestContact",
  props: {
    clientName: String,
    attorneyName: String,
    attorneyEmail: String,
    caseManagerName: String,
    caseManagerEmail: String,
    CMPhoneNumber: String,
    caseNumber: String,
    caseStatus: String,
    attorneyPhoneNumber: String,
    recordID: String,
    globalID: String,
  },
  methods: {
    contactProgressBar: function () {
      this.showProgress = true;
      for (let i = 0; i < 101; i++) {
        setTimeout(() => {
          this.progressNumber += 1;
        }, 1000);
        if (this.progressNumber == 99) {
          this.showProgress = false;
        }
      }
    },
    checkLength() {
      if (this.clientNote.length >= 5) {
        // console.log("check length");
      }
    },
    requestContact: function () {
      // this.contactProgressBar().then(alert('test'))
      this.requested = true;

      if (this.validated == false) {
        // alert("Check case # and/or PIN and try again.")
      } else {
        axios
          .post("api/email", {
            clientName: this.clientName,
            caseManagerEmail: this.caseManagerEmail,
            attorneyEmail: this.attorneyEmail,
            caseNumber: this.caseNumber,
            caseStatus: this.caseStatus,
            clientNote: this.clientNote,
            globalID: this.globalID,
            recordID: this.recordID,
          })
          .then((response) => {
            console.log("test", response.data);
          })
          .catch((error) => {
            console.log(error);
            console.log("Too many requests.");
            this.disableButton = true;
          });
      }
    },
  },
  computed: {
    progressClasses() {
      let result = "progress ";
      if (this.currentStep && this.currentStep.label === "complete") {
        return (result += "progress--complete");
      }
      return (result += `progress--${
        this.steps.indexOf(this.currentStep) + 1
      }`);
    },
  },
  mounted() {
    this.attorneyNameRendered = this.attorneyName;
    this.attorneyEmailRendered = this.attorneyEmail;
    this.caseManagerNameRendered = this.caseManagerName;
    this.caseManagerEmailRendered = this.caseManagerEmail;
    this.CMPhoneNumberRendered = this.CMPhoneNumber;
    this.attorneyPhoneNumberRendered = this.attorneyPhoneNumber;

    if (this.caseManagerName == "") {
      this.caseManagerNameRendered = "Contact Us";
    }
    if (this.attorneyName == "") {
      this.attorneyNameRendered = "Contact Us";
    }

    if (this.CMPhoneNumber == "") {
      this.CMPhoneNumberRendered = "​770-502-6116";
    }

    if (this.attorneyPhoneNumber == "") {
      this.attorneyPhoneNumberRendered = "​770-502-6116";
    }

    // if (this.caseManagerEmail == "") {
    //   this.caseManagerEmail = "intake@glenncambre.com";
    // }

    // if (this.attorneyEmail == "") {
    //   this.attorneyEmail = "intake@glenncambre.com";
    // }
  },
};
</script>

<style>
#requestContactContainer {
  padding: 20px;
}

.smallText {
  font-size: 14px;
}

.hoverDisabled {
  background: linear-gradient(145deg, #696969, #444444);
  border-radius: 50px;
  border: 1px solid rgb(184, 184, 184);
  width: 40%;
  padding: 10px;
  cursor: no-drop;
  color: white;
}

#disabledText {
  color: red;
  border: 1px solid red;
  border-radius: 30px;
  padding: 5px;
  font-size: 14px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.37);
  background-color: rgba(241, 241, 241, 0.527);
}

.hover {
  background: linear-gradient(145deg, #f3f3f3, #ffffff);
  border-radius: 50px;
  border: 1px solid rgb(184, 184, 184);
  width: 40%;
  padding: 10px;
}

.hover:hover {
  box-shadow: 2px 2px 5px rgb(192, 192, 192);
  transition-duration: 0.3s;
  color: green;
  /* letter-spacing: .5px; */

  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
}

/**
 * Some Generic Styling
 */
*,
*:after,
*:before {
  box-sizing: border-box;
}
h1 {
  margin-bottom: 1.5em;
}
.steps {
  margin-bottom: 3em;
}
.scroll::-webkit-scrollbar {
  display: none;
}
body {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: #6d6875;
}
</style>
