<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
document.title = "Cambre & Associates Client Portal";
//Cambre Teal Hex Code - 5bc5c0

export default {
  name: "App",
};
</script>

<style>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: rgb(0, 0, 0) !important;
  background-color: #5bc5c0 !important;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 50px !important;
}

.btn,
.btn-outline-primary {
  background: #5bc5c0 !important;
  border: none !important;
  color: black !important;
  border-radius: 20px !important;
  font-size: 18px !important;
}

.btn:hover,
.btn-outline-primary:hover {
  background: #306865 !important;
  color: #ffffff !important;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.226);
}

.nav-link {
  border-radius: 50px !important;
}
#logo {
  width: 30%;
  max-width: 500px;
  min-width: 250px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 40px;
  background-color: #313131;
}

html {
  background-color: #313131;
}

.card-link {
  color: #3f73d3 !important;
}
</style>
